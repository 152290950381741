body {
  margin: 0;
  font-family: 'Avenir Regular';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: 'Avenir Heavy';
  src: url("../fonts/Avenir-Heavy.ttf") format("truetype");
}

@font-face {
  font-family: 'Avenir Regular';
  src: url("../fonts/Avenir-Regular.ttf") format("truetype");
}

h1, h2, h3, h4, h5, h6 {
  color: #646464;
  font-family: 'Avenir Heavy' !important;
}

p {
  color: #646464;
  font-family: 'Avenir Regular' !important;
}

a {
  text-decoration: none !important;
}

hr {
  height: 1px;
  margin-top: 10px !important;
  margin-bottom: 20px !important;
  border-top: 1px solid #dddddd;
  border-left: 0;
  border-right: 0;
  border-bottom: 0;
  max-width: 940px;
  width: 100%;
}